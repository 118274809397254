.special-opinion-wrap {
    display: flex;
    flex-direction: column;
    gap: 24px;
}
.special-opinion-wrap.hidden {
    display: none;
}
.special-opinion__textarea {
    resize: none;
    border: 1px solid rgba(54, 59, 77, 0.2);
    padding: 20px 20px;
    color: rgba(54, 59, 77, 0.9);
    border-radius: 8px;
    outline: none;
    height: 100px;
    font-weight: 300;
}
.special-opinion__textarea::placeholder {
    color: rgba(54, 59, 77, 0.4);
}
.special-opinion-send-file {
    display: flex;
    flex-direction: column;
    gap: 12px;
}
.special-opinion-send-file__block {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    cursor: pointer;
}
.special-opinion-send-file__icon {
    object-fit: cover;
}
.special-opinion-send-file__label {
    line-height: 24px;
    font-size: 16px;
    font-weight: 300;
    color: #0084FE;
}
.special-opinion-send-file__input {
    display: none;
}
.special-opinion-sign {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 24px;
}
.special-opinion-sign__item {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    cursor: pointer;
}
.special-opinion-sign__name-sign {
    font-size: 18px;
    color: rgba(54, 59, 77, 0.9);
    font-weight: 400;
    line-height: 20px;
}
.special-opinion-sign__icon {

}
.special-opinion-button {
    width: 343px;
    height: 48px;
    cursor: pointer;
    background: #FFFFFF;
    border: 1px solid #0084FE;
    border-radius: 4px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #0084FE;
    display: flex;
    align-items: center;
    justify-content: center;
}
.special-opinion-button:hover {
    background: #0084FE;
    border: 1px solid #FFFFFF;
    color: #FFFFFF;
}
.special-opinion-send-file__name {
    font-size: 16px;
    color: rgba(54, 59, 77, 0.9);
}
.special-opinion-send-file__name-block {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.special-opinion-send-file__close {
    cursor: pointer;
    height: auto;
    width: 12px;
    margin-left: 12px;
}
/*------Адаптивная версия---------------------------------------------------------------------------------------------*/
@media (max-width: 767px) {
    .special-opinion-sign__name-sign {
        font-size: 16px;
    }
    .special-opinion-sign {
        flex-direction: column;
        align-items: start;
    }
}