.vote-button {
    width: 170px;
    height: 48px;
    margin: 0 16px 0 0;
    cursor: pointer;
    background: #0084FE;
    border-radius: 4px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;

    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
}
.vote-button_disabled {
    background: rgba(54, 59, 77, 0.08);
    color: rgba(54, 59, 77, 0.35);
    cursor: default;
}