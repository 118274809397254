/*-----Общий контейнер для двух div со статусами и датами рег-ции и голосования---------------------------------------*/
.details-votes-page-general-info__main-content {
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
    justify-content: space-between;
    padding-bottom: 40px;
    position: relative;
}
.main-content-current-status-vote__title {
    font-weight: 600;
}
.status-vote-possible-revote__add-border-right {
    border-right: 1px rgba(54, 59, 77, 0.2) solid;
}
/*-----Стилизация div для статусов рег-ции и голосования-------------------------------------------------------------*/
.details-votes-page-general-info__main-content-current-status-vote {
    display: flex;
    flex-direction: column;
    gap: 34px;
}
.status-vote-possible-revote__width-block {
    max-width: 168px;
}
/*-----Стилизация div для статусов рег-ции и голосоваия-------------------------------------------------------------*/
.main-content-current-status-vote__status-vote-possible-revote {
    display: flex;
    flex-direction: row;
}
.main-content-current-status-vote__status-vote-possible-revote:nth-child(1) {
    border-left: 1px gray solid;
}
/*-----Доп стили для скрытия блоков мобильной версии------------------------------------------------------------------*/
._hidden-materials-vote {
    display: none;
}
._show-possible-cancel-block {
    display: none;
}
/*---------Адаптивная версия-----------------------------------------------------------------------------------------*/
@media (max-width: 1357px) {
    .details-votes-page-general-info__main-content-current-status-vote {
        gap: 16px;
    }
    .details-votes-page-general-info__main-content {
        display: grid;
        grid-row-gap: 20px;
        border: none;
        padding-left: 0;
        padding-bottom: 24px;
    }
}
@media (max-width: 767px) {
    .details-votes-page-general-info__main-content {
        /*gap: 24px;*/
        display: flex;
        flex-direction: column;
        padding-bottom: 0;
        margin-bottom: 12px;

    }
    .main-content-current-status-vote__status-vote-possible-revote {
        display: grid;
        /*row-gap: 8px;*/
    }
    ._hidden-materials-vote {
        display: block;
        padding-bottom: 24px;
    }
    ._hidden-possible-cancel-block {
        display: none;
    }
    ._show-possible-cancel-block{
        display: block;
    }
    .status-vote-possible-revote__width-block {
        max-width: 100%;
    }
}



