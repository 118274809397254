/*------Основной стиль для модального окна----------------------------------------------------------------------------*/
.materials-vote-question-modal__wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: absolute;
    background-color: #FFFFFF;
    left: 0;
    top: 22px;
    opacity: 0;
    z-index: 2;
    cursor: auto;
    opacity: 1;
    transform: scale(1);
    pointer-events: all;
    padding: 24px 24px;
    box-shadow: 0 4px 8px rgba(54, 59, 77, 0.1);
    max-width: 500px;
}
.materials-vote-question-modal__link-materials {
    display: flex;
}
.materials-vote-question-modal__link-materials a {
    color: #0084FE;
    font-size: 18px;
    line-height: 133%;
    opacity: 0.9;
    white-space: break-spaces;
    word-break: break-word;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
/*------Стилизация для div с документами------------------------------------------------------------------------------*/
.materials-vote-question-modal__document-icons {
    display: flex;
    flex-direction: row;
    gap: 24px;    
    justify-content: space-between;
    align-items: center;
}
.materials-vote-question-modal__document-icons-name-doc {
     word-wrap: break-word;
     line-height: 133%;
     font-size: 18px;
     color: rgba(54, 59, 77, 0.9);
     overflow: hidden;
     white-space: nowrap;
     text-overflow: ellipsis;
}
/*------Стилизация для div с иконками--------------------------------------------------------------------------------*/
.document-icons__icons-block {
    display: flex;
    flex-direction: row;
    gap: 8px;
}
.document-icons__icons-block-image {
    cursor: pointer;
    width: 28px;
    height: 28px;
}
/*---------Адаптивная версия-----------------------------------------------------------------------------------------*/
@media (max-width: 1280px) {
    .materials-vote-question-modal__wrapper {
        padding: 16px 16px;
        gap: 12px;
        max-width: 300px;
    }
    .materials-vote-question-modal__document-icons-name-doc {
        font-size: 14px;
    }
    .materials-vote-question-modal__link-materials a {
        font-size: 14px;
    }
    .document-icons__icons-block-image {
        width: 20px;
        height: 20px;
    }
}
@media (max-width: 420px) {
    .materials-vote-question-modal__wrapper {
        max-width: 100%;
        left: 0;
        gap: 10px;
    }
    .materials-vote-question-modal__document-icons-name-doc  {
        font-size: 12px;
    }
    .materials-vote-question-modal__link-materials a {
        font-size: 12px;
    }
}