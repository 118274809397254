/*------Стилизация для div с вопросом---------------------------------------------------------------------------------*/
.read-questions-card-list__list-question-block {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid rgba(54, 59, 77, 0.2);
    padding-bottom: 16px;
    width: 100%;
}
.list-question-block__title {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-bottom: 16px;
}
.list-question-block__title h3 {
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    color: rgba(54, 59, 77, 0.9);
    word-break: break-word;
}
.list-question-block__title h5 {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: rgba(54, 59, 77, 0.4);
    word-break: break-word;
}
.list-question-block__title span {
    color: rgba(54, 59, 77, 0.9);
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
}
.list-question-block__title select {
    background-color: #FFFFFF;

    cursor: pointer;
}

/*------Адаптивная версия(800px)---------------------------------------------------------------------------------------*/
@media (max-width: 992px) {
    .list-question-block__title {
        gap: 8px;
        padding-bottom: 16px;
    }
    .list-question-block__title h3 {
        font-size: 18px;
        font-weight: 500;
    }

    .list-question-block__title h5 {
        font-size: 12px;
        font-weight: 500;
    }
    .list-question-block__title span {
        font-size: 12px;

    }
}