.my-profile-page-set-mail-phone-change {
    display: flex;
    flex-direction: row;
    gap: 8px;
}
.my-profile-page-set-mail-phone__email {
    font-size: 18px;
    color: rgba(54, 59, 77, 0.9);
    font-weight: 400;
    cursor: pointer;
}
.my-profile-page-set-mail-phone__email.active {
    font-size: 18px;
    font-weight: 400;
    color: #0084FE;
    cursor: default;
    pointer-events: none;
}
.my-profile-page-set-mail-phone__email:hover {
    color: #0084FE;
}
.my-profile-page-set-mail-phone__phone {
    font-size: 18px;
    color: rgba(54, 59, 77, 0.9);
    font-weight: 400;
    cursor: pointer;
}
.my-profile-page-set-mail-phone__phone.active {
    font-size: 18px;
    font-weight: 400;
    color: #0084FE;
    cursor: default;
    pointer-events: none;
}
.my-profile-page-set-mail-phone__phone:hover {
    color: #0084FE;
}
.my-profile-page-set-mail-phone__form {
    position: relative;
}
.my-profile-page-set-mail-phone__error {
    color: #FF4970;
    font-size: 12px;
    position: absolute;
    top: 94px;
}
