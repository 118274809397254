/*------Основной контейнер--------------------------------------------------------------------------------------------*/
.details-votes-page-read-questions__main-content {
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
    border-radius: 24px;
    gap: 12px;
}
/*------Заголовок основного контейнера--------------------------------------------------------------------------------*/
.details-votes-page-read-questions__wrapper-title {
    font-size: 20px;
    font-weight: 600;
    color: #4A4F5F;
    line-height: 32px;
}
/*------Адаптивная версия(824px)---------------------------------------------------------------------------------------*/
@media (max-width: 992px) {
    .details-votes-page-read-questions__wrapper {
        padding-bottom: 0;
        gap: 16px;
    }
    .details-votes-page-read-questions__main-content {
        padding: 0;
    }
    .details-votes-page-read-questions__wrapper-title {
        font-size: 20px;
    }

}
@media (max-width: 992px) {
    .details-votes-page-read-questions__main-content {
        gap: 20px;
        margin-bottom: 16px;
    }
    .details-votes-page-read-questions__wrapper-title {
        display: none;
    }

}