.status-day-color__green-color-item {
    text-align: center;
    height: 24px;
    border-radius: 24px;
    font-size: 12px;
    line-height: 20px;
    padding: 2px 12px 2px 12px;
    background-color: rgba(78, 212, 169, 0.2);
    color: #4ED4A9;
    margin: 0 auto;
}
@media (max-width: 992px) {
    .status-day-color__green-color-item {
        font-size: 10px;
        border-radius: 8px;
    }
}
