/*------Базовые стили------------------------------------------------------------------------------------------------*/
*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  border: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

img {
  vertical-align: top;
  /* max-width: 100%; */
  height: auto;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: inherit;
  font-size: inherit;
}
html,
body {
  min-width: 320px;
  height: 100%;
  line-height: 1;
  font-size: 18px;
  background-color: #fbfbfc;
  font-family: Inter;
  color: #363b4d;
}

input {
  color: #363b4d;
  font-family: inherit;
}

._container {
  max-width: 1328px;
  padding: 0 15px;
  margin: 0 auto;
  box-sizing: content-box;
}
button {
  font-family: Inter;
}
button:disabled,
button:hover:disabled,
button:active:disabled,
button:focus:disabled {
  background-color: #e4e4e4 !important;
  border: none !important;
  cursor: default;
  color: #fefefe !important;
}

body::-webkit-scrollbar {
  width: 8px; /* ширина всей полосы прокрутки */
}
body::-webkit-scrollbar-track {
  background: rgba(54, 59, 77, 0.1); /* цвет зоны отслеживания */
}
body::-webkit-scrollbar-thumb {
  background-color: gray; /* цвет бегунка */
  border-radius: 20px; /* округлось бегунка */
  /* отступ вокруг бегунка */
}
